.QuizsContainer {
  flex: 4;
  background-color: rgb(193, 221, 241);
}

.QuizsTitle {
  display: flex;
}
.textQuizsTitle {
  margin-left: 100px;
  font-size: 40px;
  font-weight: 600;
  color: #2077d8;
}

.tabQuizContainer {
  display: flex;
}

.AddQuizContainer {
  margin-left: 50px;
  margin-right: 50px;
  height: 250px;
  width: 200px;
  padding: 1rem;
  border-radius: 5%;
  border: 3px dashed #2077d8;
}

.EditQuizContainer {
  margin-left: 50px;
  margin-right: 50px;
  height: 250px;
  width: 200px;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5%;
  border: 1px solid #000000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ImgQuizContainer {
  width: 150px;
  height: 150px;
}

.SkillsTitle {
  font-weight: 600;
  font-size: 20px;
  margin: 6px;
}

.BtnHead {
  flex: 1;
  margin: 1px;
  padding-left: 5px;
  width: 30px;
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 214, 95, 0.5);
  color: black;
  font-weight: 600;
  font-size: 16px;
  text-align: start;
}

.BtnEditContainer {
  margin: 1px;
  padding: 7px 20px 7px 20px;
  border: none;
  border-radius: 16px;
  background-color: #0078ff;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

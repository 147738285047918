.login-card-container {
  min-height: 100vh;
  background: linear-gradient(to right, #d3ab61, #7db9db);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 450px;
  background: rgba(255, 255, 255, 0.5);
  padding: 4rem;
  border-radius: 10px;
  position: relative;
  box-shadow: 8px 10px 5px #606060;
  z-index: 0;
}

.login-card::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.15);
  inset: 0;

  border-radius: 10px;
  z-index: -1;
}
.login-card-logo {
  margin-bottom: 2rem;
}
.login-card-logo img {
  width: 100px;
}
.login-card-logo,
.login-card-header,
.login-card-footer {
  text-align: center;
}

.login-card a {
  text-decoration: none;
  color: #35339a;
}

.login-card a:hover {
  text-decoration: underline;
}
.login-card-header {
  margin-bottom: 2rem;
}
.login-card-header h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.login-card-header h1 + div {
  font-size: calc(1rem * 0.8);
  opacity: 0.8;
}
.login-card-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.login-card-form .form-item {
  position: relative;
}
.login-card-form .form-item .form-item-icon {
  position: absolute;
  top: 1rem;
  left: 1.4rem;
  font-size: 1.3rem;
  opacity: 0.4;
}

.login-card-form .checkbox {
  display: flex;
  gap: 10px;
}

.form-item-other label {
  text-align: start;
  font-size: 12px;
  font-weight: 600;
}
.login-card-form .form-item-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(1rem * 0.8);
}
.login-card-footer {
  margin-top: 1.5rem;
  font-size: calc(1rem * 0.8);
}
.login-card input[type="text"],
.login-card input[type="password"],
.login-card input[type="email"],
.login-card input[type="tel"] {
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
  padding-left: calc(1rem * 3.5);
  border-radius: 100px;
  width: 100%;
  transition: background 0.5s;
}
.login-card input:focus {
  background: white;
}
.login-card input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: black;
  margin-left: 30px;
}
.login-card-form button {
  background: black;
  color: white;
  padding: 1rem;
  border-radius: 100px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transform: background 0.5s;
}
.login-card-form button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}
.login-card-social {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  margin-top: 3rem;
}
.login-card-social > div {
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: calc(1rem * 0.8);
}
.login-card-social-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.login-card-social-btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 100px;
  transition: all 0.5s;
}
.login-card-social-btns a:hover {
  background: white;
  transform: scale(1.1);
}

.StartQuiz {
  max-width: 960px;
}

.contalner-StartQuiz {
  margin-top: 100px;
}

.StartQuiz-con {
  align-items: center;
  justify-content: center;
  height: 480px;
  background-color: fff;
}

.StartQuizBody {
  padding: 1rem;
  border-radius: 10px;
}

.BtnAnswer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.BtnAnswer button {
  width: 40%;
  padding: 10px 15px;
  margin: 0 10px 20px 10px;
  color: #ffff;
  background: #29268b;
  border-radius: 10px;
  text-align: center;
}

.BtnAnswer button:hover {
  color: rgb(184, 183, 183);
  background: #39393a;
}

.Quiz-Part {
  border: 2px solid rgb(157, 157, 157);
  border-radius: 16px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.Quiz-Part #p1 {
  position: absolute;
  width: 100%;
  background-color: #ffd65f;
  text-align: start;
  top: 0;
  padding: 5px 5px 5px 30px;
  border-radius: 15px 15px 0px 0px;
}

.Quiz-Part h2 {
  text-align: start;
  margin-left: 50px;
  padding: 5px;
}

.QuizResult {
  border: 1px solid black;
  border-radius: 10px;
  padding: 50px;
}

.QuizResult .QuizResultTitle {
  text-align: start;
  color: #0063d3;
}

.BtnTypeChart {
  display: flex;
}

.BtnTypeChart Button {
  margin: 10px;
}
.ViewFull {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ViewAsScore {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  border: 7px solid #0078ff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-top: 10px;
  padding-top: 20px;
}

.OverallScore {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  border: 7px solid #a8d036;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-top: 10px;
  padding-top: 20px;
}

.TypeScore {
  display: flex;
  width: 30%;
  flex-direction: column;
  margin-left: 50px;
}

.TypeScore p {
  text-align: start;
}

.Suggestion {
  text-align: start;
  margin-left: 50px;
}

.Suggestion h2 {
  font-weight: 700;
}

.Suggestion h4 {
  font-weight: 500;
}

.Suggestion p {
  font-size: 16px;
}

.SuggestionBody {
  margin-left: 50px;
  margin-top: 20px;
}

.RecommendedCourse {
  margin-top: 50px;
  margin-left: 50px;
}

.RecommendedCourse h2 {
  text-align: start;
  font-weight: 700;
}
.ViewSchedule {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: 40px;
}

.ViewSchedule {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.ViewSchedule p {
  margin: 20px 40px 20px 40px;
}
.ViewSchedule .head-color {
  background-color: #ffd65f;
  padding: 40px 20px;
  border-radius: 15px 0px 0px 15px;
}
.ViewSchedule img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0px 60px 0px 60px;
}

.NavbarItem {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 80px;
  border-radius: 0px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  z-index: 50;
}
.logo {
  color: #000;
  justify-self: start;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
  margin-top: 19px;
}

.nav-links {
  text-decoration: none;
  color: #000;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.nav-links-In {
  margin-left: 50px;
  text-decoration: none;
  color: #000;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.nav-links-In:hover {
  background: rgb(226, 226, 226);
  color: rgb(77, 77, 77);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.nav-links i {
  padding-right: 10px;
}
.nav-links:hover {
  background: rgb(226, 226, 226);
  color: rgb(77, 77, 77);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.fa-bars,
.fa-times {
  color: rgb(255, 255, 255);
  padding: 8px;
  background-color: #dc3545;
  border-radius: 5px;
}

.menu-icons {
  display: none;
}

.nav-links-mobile {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #0078ff;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.nav-links-mobile:hover {
  color: rgb(8, 8, 8);
  background-color: #278afa;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0%;
  }

  .nav-links {
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: #222;
  }

  .nav-links:hover {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
  }

  .menu-icons {
    display: block;
  }
  .NavbarItem {
    justify-content: space-between;
  }
  .nav-links-mobile {
    padding: 1.5rem;
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    width: 80%;
    margin: auto;
  }
}

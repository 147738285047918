.SingInOutContainer1 {
    flex: 4;
    background-color: rgb(193, 221, 241);
}

.SingContainer {
    display: flex;
    margin-top: 10px;
    margin-left: 100px;
    margin-right: 100px;

}

.SingTime {
    flex: 2;
    background-color: #FF5F5F;
    color: white;
    border-radius: 10px 0 0 10px;
    padding: 5px;
}

.SingCode {
    flex: 3;
    background-color: white;
    border-radius: 0 10px 10px 0;
}

.SizeDay {
    font-size: 25px;
}

.SizeTime {
    font-size: 40px;
}
.headBarcode{
    text-align: start;
    margin-left: 150px;
    font-size: 20px;
    font-weight: 600;
}
.TitleBarcode{
    text-align: start;
    margin-left: 150px;
    font-size: 16px;
    font-weight: 500;
}
.InputBarcode{
    padding: 10px;
    border-radius: 10px;
    padding-left: 20px;
}
.BtnConfirm{
    margin-left: 200px;
    height: 40px;
    width: 180px;
    padding: 6px 30px 6px 30px;
    border: none;
    border-radius: 5px;
    background-color: #3be38c;
    color: white;
    font-weight: 600;
}
.DataGridSingInOut{
    background-color: white;
    border-radius: 20px;
    margin: 30px;
    margin-left: 150px;
}